import { eventChannel, EventChannel } from "@redux-saga/core";
import { call, cancel, delay, fork, put, select, take } from "redux-saga/effects";
import { Socket } from "socket.io-client";

// Slice
import { API_WEBSOCKET_URL } from "@env";
import { authActions, selectAuthLoggedIn, selectedAuthToken } from "../slices/auth.slice";
import { lobbyActions } from "../slices/lobby.slice";
import { socketActions } from "../slices/socket.slice";


// Define Action Type
interface SocketEventAction {
  type: string;
  payload?: any;
  error?: string;
}

// WebSocket connection function
function connectSocket(sessionToken: any): WebSocket {
  const ws = new WebSocket(`${API_WEBSOCKET_URL}/ws/user-balances?accessToken=${sessionToken}`);

  return ws;
}

// Create event channel with proper typing
function createSocketChannel(ws: any): EventChannel<SocketEventAction> {
  return eventChannel((emit) => {
    // Handle connection open
    ws.onopen = () => {
      console.log("WebSocket Connected");
      emit({ type: "socket/socketConnected" });
    };

    // Handle messages
    ws.onmessage = (event: any) => {
      console.log("eventevent", event);
      emit({ type: "user/updateWallet", payload: JSON.parse(event.data) });
    };

    // Handle errors
    ws.onerror = (error: any) => {
      console.error("WebSocket Error:", error);
      emit({ type: "socket/socketError", payload: error });
    };

    // Handle connection close
    ws.onclose = (event: any) => {
      console.log("WebSocket Disconnected", event.reason);
      emit({ type: "socket/socketDisconnected" });
    };

    return () => {
      ws.close();
    };
  });
}

// WebSocket saga with proper typing
function* handleWebSocketConnection(): Generator<any, void, SocketEventAction> {
  const isLoggedIn = yield select(selectAuthLoggedIn);
  const sessionToken = yield select(selectedAuthToken);

  if (!isLoggedIn || !sessionToken) return;

  let retryCount = 0;
  let socket: Socket | any;
  let socketChannel: any | null = null;

  while (retryCount < 5) {
    try {
      socket = yield call(connectSocket, sessionToken);
      socketChannel = yield call(createSocketChannel, socket);
      while (true) {
        const action: SocketEventAction = yield take(socketChannel); // 🔥 Fix: TypeScript now recognizes action type
        yield put(action);
      }
    } catch (error) {
      yield put(socketActions.socketError(error));

      // Exponential backoff for reconnection
      const delayTime = Math.min(2 ** retryCount * 1000, 30000);
      yield delay(delayTime);

      retryCount += 1;
    } finally {
      if (socketChannel) {
        socketChannel.close();
      }
    }
  }

  yield put(socketActions.socketDisconnected());
}

// Saga to watch for token updates and user login/logout
function* watchAuthState(): Generator<any, void, any> {
  let socketTask: any;

  while (true) {
    yield take([authActions.refreshToken.type, authActions.logout.type, lobbyActions.lobbySuccess.type]);

    if (socketTask) {
      yield cancel(socketTask);
    }

    const isLoggedIn = yield select(selectAuthLoggedIn);
    const sessionToken = yield select(selectedAuthToken);

    if (isLoggedIn && sessionToken) {
      socketTask = yield fork(handleWebSocketConnection);
    }
  }
}

export default watchAuthState;
